
export default {
	name: "CoreIconCircleCheck",
	props: {
		settings: {
			type: Object,
			required: false,
		},
	},
	computed: {
		alt() {
			return this.settings?.alt || this.settings?.icon;
		},
		id() {
			return this.settings?.id || this.settings?.icon;
		},
	},
};
